import React, { useState, useEffect } from 'react';
import { 
    Typography, Container, Box, Grid, Card, CardContent, IconButton, Dialog, 
    DialogTitle, DialogContent, DialogActions, CircularProgress, Accordion, 
    AccordionSummary, AccordionDetails, TextField, Button, Fab, FormControl, 
    InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, Chip,
    RadioGroup, Radio, FormControlLabel, FormLabel, Paper, Divider, Tooltip
} from '@mui/material';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, getDoc, deleteDoc, onSnapshot, Timestamp } from 'firebase/firestore';
import { auth, db } from './firebase';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from './AlertContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
// Icônes pour les types de messages
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// Éditeur de texte enrichi
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

dayjs.locale('fr');

// Configuration des types de messages
const MESSAGE_TYPES = [
    { 
        id: 'info', 
        label: 'Information', 
        icon: <InfoIcon />, 
        color: '#1976D2', 
        textColor: 'white',
        description: 'Message d\'information général'
    },
    { 
        id: 'success', 
        label: 'Succès', 
        icon: <CheckCircleIcon />, 
        color: '#2E8B57', // Vert forêt plus doux que le vert vif
        textColor: 'white',
        description: 'Annonce positive ou succès'
    },
    { 
        id: 'warning', 
        label: 'Avertissement', 
        icon: <WarningIcon />, 
        color: '#EB984E', // Orange utilisé dans l'AppBar
        textColor: 'white',
        description: 'Avertissement ou information importante'
    },
    { 
        id: 'error', 
        label: 'Urgent', 
        icon: <ErrorIcon />, 
        color: '#B22222', // Rouge foncé au lieu du rouge vif
        textColor: 'white',
        description: 'Message urgent ou critique'
    },
    { 
        id: 'announcement', 
        label: 'Annonce', 
        icon: <NotificationsActiveIcon />, 
        color: '#5D6D7E', // Gris bleuté au lieu du violet
        textColor: 'white',
        description: 'Annonce ou événement important'
    }
];

// Configuration de l'éditeur Quill
const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link'],
        ['clean']
    ],
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'color', 'background',
    'link'
];

const MessageAdmin = ({ userRole }) => {
    const [messages, setMessages] = useState([]);
    const [cliniques, setCliniques] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const { showAlert } = useAlert();
    
    // État pour le formulaire
    const [currentMessage, setCurrentMessage] = useState({
        id: null,
        texte: '',
        html: '',
        type: 'info',
        cliniquesIds: [],
        dateDebut: dayjs(),
        dateFin: dayjs().add(7, 'day'),
        createdAt: null
    });

    // Récupérer toutes les cliniques (utilisateurs veto)
    useEffect(() => {
        const fetchCliniques = async () => {
            try {
                const cliniqueCollectionRef = collection(db, "veto");
                const cliniqueSnapshot = await getDocs(cliniqueCollectionRef);
                const cliniqueData = cliniqueSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .sort((a, b) => a.clinique.localeCompare(b.clinique));
                setCliniques(cliniqueData);
            } catch (error) {
                console.error("Erreur lors de la récupération des cliniques:", error);
                showAlert("Erreur lors du chargement des cliniques", "error");
            }
        };

        fetchCliniques();
    }, []);

    // Récupérer les messages
    useEffect(() => {
        const messagesCollectionRef = collection(db, "messages");
        const unsubscribe = onSnapshot(messagesCollectionRef, (snapshot) => {
            const messagesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
            // Convertir les objets Timestamp en dayjs
            const formattedMessages = messagesData.map(message => ({
                ...message,
                dateDebut: message.dateDebut ? dayjs(message.dateDebut.toDate()) : dayjs(),
                dateFin: message.dateFin ? dayjs(message.dateFin.toDate()) : dayjs(),
                // Assurer la compatibilité avec les anciens messages qui n'ont pas de type ou de html
                type: message.type || 'info',
                html: message.html || message.texte || ''
            }));
            
            setMessages(formattedMessages);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const handleOpenDialog = (message = null) => {
        if (message) {
            // Mode édition
            setCurrentMessage({
                ...message,
                id: message.id,
                type: message.type || 'info',
                html: message.html || message.texte || ''
            });
            setEditMode(true);
        } else {
            // Nouveau message
            setCurrentMessage({
                id: null,
                texte: '',
                html: '',
                type: 'info',
                cliniquesIds: [],
                dateDebut: dayjs(),
                dateFin: dayjs().add(7, 'day'),
                createdAt: null
            });
            setEditMode(false);
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCurrentMessage({
            id: null,
            texte: '',
            html: '',
            type: 'info',
            cliniquesIds: [],
            dateDebut: dayjs(),
            dateFin: dayjs().add(7, 'day'),
            createdAt: null
        });
        setEditMode(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentMessage(prev => ({ ...prev, [name]: value }));
    };

    const handleCliqueChange = (event) => {
        const {
            target: { value },
        } = event;
        setCurrentMessage(prev => ({
            ...prev,
            cliniquesIds: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const handleHtmlChange = (html) => {
        // Extraire le texte brut pour la compatibilité avec les anciennes versions
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        const texte = tempDiv.textContent || tempDiv.innerText || "";
        
        setCurrentMessage(prev => ({ 
            ...prev, 
            html: html,
            texte: texte
        }));
    };

    const handleTypeChange = (e) => {
        setCurrentMessage(prev => ({ ...prev, type: e.target.value }));
    };

    const handleDateDebutChange = (date) => {
        setCurrentMessage(prev => ({ ...prev, dateDebut: date }));
    };

    const handleDateFinChange = (date) => {
        setCurrentMessage(prev => ({ ...prev, dateFin: date }));
    };

    const handleSaveMessage = async () => {
        if (!currentMessage.html || currentMessage.cliniquesIds.length === 0) {
            showAlert("Veuillez remplir tous les champs obligatoires", "error");
            return;
        }

        try {
            console.log("Cliniques sélectionnées:", currentMessage.cliniquesIds);
            console.log("Date début:", currentMessage.dateDebut.format('DD/MM/YYYY'));
            console.log("Date fin:", currentMessage.dateFin.format('DD/MM/YYYY'));
            console.log("Type de message:", currentMessage.type);
            
            // Préparer les données à enregistrer
            const messageData = {
                texte: currentMessage.texte,
                html: currentMessage.html,
                type: currentMessage.type,
                cliniquesIds: currentMessage.cliniquesIds,
                dateDebut: Timestamp.fromDate(currentMessage.dateDebut.toDate()),
                dateFin: Timestamp.fromDate(currentMessage.dateFin.toDate()),
                createdAt: currentMessage.createdAt || Timestamp.now()
            };

            if (editMode && currentMessage.id) {
                // Mise à jour d'un message existant
                await updateDoc(doc(db, "messages", currentMessage.id), messageData);
                showAlert("Message mis à jour avec succès", "success");
            } else {
                // Création d'un nouveau message
                await addDoc(collection(db, "messages"), messageData);
                showAlert("Message créé avec succès", "success");
            }
            
            handleCloseDialog();
        } catch (error) {
            console.error("Erreur lors de l'enregistrement du message:", error);
            showAlert("Erreur lors de l'enregistrement du message", "error");
        }
    };

    const handleDeleteMessage = async (messageId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce message ?")) {
            try {
                await deleteDoc(doc(db, "messages", messageId));
                showAlert("Message supprimé avec succès", "success");
            } catch (error) {
                console.error("Erreur lors de la suppression du message:", error);
                showAlert("Erreur lors de la suppression du message", "error");
            }
        }
    };

    const isMessageActive = (message) => {
        const now = dayjs();
        return now.isAfter(message.dateDebut) && now.isBefore(message.dateFin);
    };

    const getCliniqueNames = (cliniquesIds) => {
        return cliniquesIds.map(id => {
            const clinique = cliniques.find(c => c.id === id);
            return clinique ? clinique.clinique : '';
        }).filter(Boolean);
    };

    const getMessageTypeInfo = (typeId) => {
        return MESSAGE_TYPES.find(type => type.id === typeId) || MESSAGE_TYPES[0];
    };

    if (userRole !== 'admin') {
        return (
            <Container>
                <Typography variant="h6" align="center" sx={{ marginTop: 4 }}>
                    Vous n'avez pas les droits pour accéder à cette page
                </Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ paddingBottom: 15 }}>
            <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
                Gestion des messages personnalisés
            </Typography>
            
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {messages.length === 0 ? (
                        <Typography variant="h6" align="center" sx={{ marginTop: 4 }}>
                            Aucun message n'a été créé
                        </Typography>
                    ) : (
                        <Box sx={{ mt: 2 }}>
                            {messages.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds).map((message) => {
                                const typeInfo = getMessageTypeInfo(message.type);
                                return (
                                    <Card key={message.id} sx={{ 
                                        mb: 2, 
                                        border: isMessageActive(message) ? `2px solid ${typeInfo.color}` : '1px solid #ccc',
                                        position: 'relative'
                                    }}>
                                        <Box sx={{ 
                                            backgroundColor: isMessageActive(message) ? typeInfo.color : '#f5f5f5',
                                            color: isMessageActive(message) ? typeInfo.textColor : 'inherit',
                                            padding: '4px 16px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {React.cloneElement(typeInfo.icon, { style: { marginRight: 8 } })}
                                                <Typography variant="subtitle1">
                                                    {typeInfo.label} - {isMessageActive(message) ? 'Actif' : 'Inactif'}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IconButton onClick={() => handleOpenDialog(message)} size="small" sx={{ color: isMessageActive(message) ? typeInfo.textColor : 'inherit' }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteMessage(message.id)} size="small" sx={{ color: isMessageActive(message) ? typeInfo.textColor : 'inherit' }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <CardContent>
                                            <Box sx={{ mb: 2 }}>
                                                {message.html ? (
                                                    <div dangerouslySetInnerHTML={{ __html: message.html }} />
                                                ) : (
                                                    <Typography variant="body1">{message.texte}</Typography>
                                                )}
                                            </Box>
                                            
                                            <Divider sx={{ my: 1.5 }} />
                                            
                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                <strong>Période :</strong> Du {message.dateDebut.format('DD/MM/YYYY')} au {message.dateFin.format('DD/MM/YYYY')}
                                            </Typography>
                                            
                                            <Box sx={{ mt: 1 }}>
                                                <Typography variant="body2">
                                                    <strong>Cliniques concernées :</strong>
                                                </Typography>
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                                                    {getCliniqueNames(message.cliniquesIds).map((nom, index) => (
                                                        <Chip key={index} label={nom} size="small" />
                                                    ))}
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                )
                            })}
                        </Box>
                    )}
                    
                    <Fab
                        color="primary"
                        aria-label="add"
                        onClick={() => handleOpenDialog()}
                        sx={{
                            position: 'fixed',
                            bottom: 80,
                            right: 30,
                            backgroundColor: "#1976D2"
                        }}
                    >
                        <AddIcon />
                    </Fab>
                </>
            )}
            
            {/* Dialog pour créer/éditer un message */}
            <Dialog 
                open={openDialog} 
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    {editMode ? "Modifier le message" : "Créer un nouveau message"}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} sx={{ mt: 0 }}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" sx={{ mb: 2, mt: 2 }}>
                                <FormLabel component="legend">Type de message</FormLabel>
                                <RadioGroup
                                    row
                                    name="type"
                                    value={currentMessage.type}
                                    onChange={handleTypeChange}
                                >
                                    {MESSAGE_TYPES.map(type => (
                                        <Tooltip key={type.id} title={type.description} arrow>
                                            <FormControlLabel 
                                                value={type.id} 
                                                control={<Radio />} 
                                                label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Box sx={{ 
                                                            display: 'flex', 
                                                            alignItems: 'center', 
                                                            justifyContent: 'center',
                                                            backgroundColor: type.color,
                                                            color: type.textColor,
                                                            borderRadius: '50%',
                                                            width: 24,
                                                            height: 24
                                                        }}>
                                                            {React.cloneElement(type.icon, { style: { fontSize: 16 } })}
                                                        </Box>
                                                        {type.label}
                                                    </Box>
                                                } 
                                            />
                                        </Tooltip>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                                Message
                            </Typography>
                            <ReactQuill 
                                value={currentMessage.html} 
                                onChange={handleHtmlChange} 
                                modules={modules}
                                formats={formats}
                                style={{ height: '200px', marginBottom: '50px' }}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom sx={{ mt: 5 }}>
                                Prévisualisation
                            </Typography>
                            <Paper elevation={1} sx={{ 
                                p: 2, 
                                backgroundColor: getMessageTypeInfo(currentMessage.type).color,
                                color: getMessageTypeInfo(currentMessage.type).textColor,
                                borderRadius: '4px'
                            }}>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                                    {React.cloneElement(getMessageTypeInfo(currentMessage.type).icon, { style: { marginTop: 3 } })}
                                    <div dangerouslySetInnerHTML={{ __html: currentMessage.html }} />
                                </Box>
                            </Paper>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="cliniques-label">Cliniques concernées</InputLabel>
                                <Select
                                    labelId="cliniques-label"
                                    multiple
                                    name="cliniquesIds"
                                    value={currentMessage.cliniquesIds}
                                    onChange={handleCliqueChange}
                                    input={<OutlinedInput label="Cliniques concernées" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => {
                                                const clinique = cliniques.find(c => c.id === value);
                                                return clinique ? (
                                                    <Chip key={value} label={clinique.clinique} />
                                                ) : null;
                                            })}
                                        </Box>
                                    )}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5 + 8,
                                                width: 250,
                                            },
                                        },
                                    }}
                                >
                                    {cliniques.map((clinique) => (
                                        <MenuItem key={clinique.id} value={clinique.id}>
                                            <Checkbox checked={currentMessage.cliniquesIds.indexOf(clinique.id) > -1} />
                                            <ListItemText primary={`${clinique.clinique} - ${clinique.ville}`} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <DatePicker
                                    label="Date de début"
                                    value={currentMessage.dateDebut}
                                    onChange={handleDateDebutChange}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth />
                                    )}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <DatePicker
                                    label="Date de fin"
                                    value={currentMessage.dateFin}
                                    onChange={handleDateFinChange}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth />
                                    )}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Annuler</Button>
                    <Button 
                        onClick={handleSaveMessage} 
                        variant="contained" 
                        color="primary"
                        startIcon={<SaveIcon />}
                    >
                        {editMode ? "Mettre à jour" : "Enregistrer"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default MessageAdmin; 