import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, Grid, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Accordion, AccordionSummary, AccordionDetails, TextField, Button, Fab, Alert } from '@mui/material';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { auth, db } from './firebase';
import { AddDemandes } from './demandes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CremaCard } from './views/cremaCard';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { fetchDemandes } from './models/getDemandes';
import dayjs from 'dayjs';

const Accueil = ({ userRole, userEmail, clinique, userID }) => {
  const [demandes, setDemandes] = useState([]);
  const [viewDemande, setViewDemande] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vetoUsers, setVetoUsers] = useState([]);
  const [config, setConfig] = useState([]);
  const [editing, setEditing] = useState(false);
  const [newText, setNewText] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchUserData = async (uid) => {
      try {
        if (uid) {
          const userDocRef = doc(db, "veto", uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            return userDocSnap.data();
          } else {
            return null;
          }
        }
        else return null
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
        return null;
      }
    };

    const fetchConfig = () => {
      try {
        const configCollectionRef = collection(db, "config");
        const unsubscribe = onSnapshot(configCollectionRef, (snapshot) => {
          const configData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setConfig(configData);
          setNewText(configData.find(item => item.type === 'accueil')?.text || '');
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Erreur lors de la récupération de la configuration:", error);
        return [];
      }
    };

    const fetchVetoUsers = async () => {
      if (userRole === 'admin') {
        try {
          const vetoCollectionRef = collection(db, "veto");
          const q = query(vetoCollectionRef);
          const vetoSnapshot = await getDocs(q);
          const vetoData = vetoSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setVetoUsers(vetoData);
        } catch (error) {
          console.error("Erreur lors de la récupération des utilisateurs veto:", error);
        }
      }
    };

    // Récupérer les messages personnalisés
    const fetchMessages = () => {
      const messagesCollectionRef = collection(db, "messages");
      const unsubscribe = onSnapshot(messagesCollectionRef, (snapshot) => {
        const messagesData = snapshot.docs.map(doc => ({ 
          id: doc.id, 
          ...doc.data(),
          // Assurer la compatibilité avec les anciens messages sans type ou html
          type: doc.data().type || 'info',
          html: doc.data().html || doc.data().texte || ''
        }));
        console.log("Messages récupérés:", messagesData);
        setMessages(messagesData);
      });
      return () => unsubscribe();
    };

    // Utiliser fetchDemandes de getDemandes.js avec un callback pour filtrer les résultats
    const setFilteredDemandes = (allDemandes) => {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      today.setUTCHours(0, 0, 0, 0);

      const filteredData = allDemandes.filter(demande =>
        (demande.deleted !== true && demande.statut !== 'Crémation effectuée' && demande.statut !== 'Urne restituée') ||
        (demande.deleted !== true && demande.statut === 'Crémation effectuée' && demande.cremaDate && 
         new Date(demande.cremaDate).setUTCHours(0, 0, 0, 0) === today.setUTCHours(0, 0, 0, 0))
      );

      setDemandes(filteredData);
      setLoading(false);
    };

    const unsubscribeDemandes = fetchDemandes(userRole, setFilteredDemandes, clinique, userID);
    fetchVetoUsers();
    fetchConfig();
    const unsubscribeMessages = fetchMessages();

    return () => {
      if (unsubscribeDemandes) {
        unsubscribeDemandes();
      }
      if (unsubscribeMessages) {
        unsubscribeMessages();
      }
    };
  }, [userRole, clinique, userID]);

  // Filtrer les messages actifs pour la clinique connectée
  const getActiveMessages = () => {
    if (!messages.length || !userID) return [];
    
    console.log("UserID actuel:", userID);
    console.log("Messages disponibles:", messages);
    
    const now = new Date();
    
    return messages.filter(message => {
      // Vérifier si le message concerne cette clinique
      const isForThisClinic = message.cliniquesIds && message.cliniquesIds.includes(userID);
      
      // Vérifier si le message est actif (entre les dates de début et de fin)
      let isActive = false;
      
      if (message.dateDebut && message.dateFin) {
        // Convertir les objets Timestamp Firestore en objets Date
        const dateDebut = message.dateDebut.toDate ? message.dateDebut.toDate() : new Date(message.dateDebut);
        const dateFin = message.dateFin.toDate ? message.dateFin.toDate() : new Date(message.dateFin);
        
        isActive = now >= dateDebut && now <= dateFin;
      }
      
      const shouldDisplay = isForThisClinic && isActive;
      
      if (isForThisClinic) {
        console.log(`Message "${message.html.substring(0, 20)}..." pour cette clinique:`, {
          isForThisClinic,
          isActive,
          shouldDisplay
        });
      }
      
      return shouldDisplay;
    });
  };

  const handleChangeConfig = async (newText) => {
    try {
      const configRef = collection(db, "config");
      const q = query(configRef, where("type", "==", "accueil"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {
        await updateDoc(doc.ref, {
          text: newText
        });
      });

      // Mettre à jour l'état local après la modification
      // fetchConfig();
      // showAlert("Configuration mise à jour avec succès !", 'success');
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la configuration:", error);
      // showAlert("Erreur lors de la mise à jour de la configuration.", 'error');
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setViewDemande(null);
    setOpenModal(false);
  };

  const renderDemandeCard = (demande) => {

    const handleEdit = (demande) => {
      setViewDemande(demande);
      handleOpenModal();
    };

    return (
      <CremaCard
        key={demande.id}
        demande={demande}
        handleEdit={handleEdit}
      />
    );
  };

  // Récupérer les messages actifs pour cette clinique
  const activeMessages = getActiveMessages();

  // Configuration des types de messages avec leurs icônes et couleurs
  const MESSAGE_TYPES = {
    info: { 
      icon: <InfoIcon />, 
      color: '#1976D2', 
      textColor: 'white'
    },
    success: { 
      icon: <CheckCircleIcon />, 
      color: '#2E8B57', // Vert forêt plus doux
      textColor: 'white'
    },
    warning: { 
      icon: <WarningIcon />, 
      color: '#EB984E', // Orange utilisé dans l'AppBar
      textColor: 'white'
    },
    error: { 
      icon: <ErrorIcon />, 
      color: '#B22222', // Rouge foncé
      textColor: 'white'
    },
    announcement: { 
      icon: <NotificationsActiveIcon />, 
      color: '#5D6D7E', // Gris bleuté
      textColor: 'white'
    }
  };

  // Obtenir les informations du type de message
  const getMessageTypeInfo = (typeId) => {
    return MESSAGE_TYPES[typeId] || MESSAGE_TYPES.info;
  };

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: 15 }}>

      {/* <Box sx={{ width: '100%', overflow: 'hidden', height: '230px', marginBottom: 2 }}>
        <img src="/bandeau.png" alt="Bandeau" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center 40%' }} />
      </Box> */}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Affichage des messages personnalisés pour cette clinique */}
          {activeMessages.length > 0 && (
            <Box sx={{ mb: 4 }}>
              {activeMessages.map((message) => {
                const typeInfo = getMessageTypeInfo(message.type);
                
                return (
                  <Alert 
                    key={message.id} 
                    severity="info" 
                    icon={React.cloneElement(typeInfo.icon, { style: { fontSize: '1.5rem', color: 'white' } })}
                    variant="filled"
                    sx={{ 
                      mb: 2, 
                      borderRadius: 2,
                      fontSize: '1rem',
                      backgroundColor: typeInfo.color,
                      color: typeInfo.textColor,
                      '& .MuiAlert-icon': {
                        color: 'white'
                      }
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: message.html }} />
                  </Alert>
                );
              })}
            </Box>
          )}

          {/* <Box sx={{ border: 'none', padding: 2, marginBottom: 4, position: 'relative', ...(config && config.some(item => item.type === 'accueil' && item.text !== '') && { borderRadius: 4, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }) }}>
            {userRole === 'admin' && (
              <IconButton
                sx={{ position: 'absolute', top: 8, right: 8 }}
                onClick={() => setEditing(true)}
              >
                <EditIcon />
              </IconButton>
            )}
            {editing ? (
              <Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={newText}
                  onChange={(e) => setNewText(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleChangeConfig(newText);
                    setEditing(false);
                  }}
                >
                  Valider
                </Button>
              </Box>
            ) : (
              config && config.filter(item => item.type === 'accueil' && item.text !== '').map((item, index) => (
                <Typography
                  key={index}
                  variant="h4"
                  sx={{
                    color: '#B22222',
                    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.3)',
                    fontFamily: 'Cursive',
                    textAlign: 'center',
                  }}
                >
                  {item.text}
                </Typography>
              ))
            )}
          </Box> */}
          {userRole === 'admin' && (
            <Box sx={{ marginTop: 4 }}>
              <Typography variant="h5" align="center" gutterBottom>
                Cliniques Vétérinaires
              </Typography>
              {vetoUsers.filter(veto => demandes.some(demande => (demande.userID || demande.UID) === veto.id)).map((veto) => (
                <Accordion key={veto.id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{veto.clinique} - {veto.ville}</Typography>
                    {demandes.some(demande => (demande.userID || demande.UID) === veto.id && demande.statut === 'Nouvelle demande') && (
                      <div style={{ 
                        width: '10px', 
                        height: '10px', 
                        backgroundColor: 'red', 
                        borderRadius: '50%', 
                        marginLeft: '8px',
                        display: 'inline-block',
                        verticalAlign: 'middle'
                      }} />
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 10 }}>
                      {
                        demandes
                          .filter(demande => (demande.userID || demande.UID) === veto.id)
                          .sort((a, b) => {
                            if (a.statut === 'Nouvelle demande' && b.statut !== 'Nouvelle demande') {
                              return -1;
                            }
                            if (a.statut !== 'Nouvelle demande' && b.statut === 'Nouvelle demande') {
                              return 1;
                            }
                            return 0;
                          })
                          .map((demande) => renderDemandeCard(demande))
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
          {userRole !== 'admin' && (
            demandes.filter(demande => demande.statut !== 'Crémation effectuée').length === 0 ? (
              <Typography variant="h6" align="center" sx={{ marginTop: 4 }}>
                Aucune nouvelle demande disponible
              </Typography>
            ) : (
              <Box>
                {demandes.some(demande => demande.statut !== 'Crémation effectuée') && (
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Demandes en cours</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3} justifyContent="center" sx={{}}>
                        {demandes
                          .filter(demande => demande.statut !== 'Crémation effectuée')
                          .map(demande => renderDemandeCard(demande))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
                {/* {demandes.some(demande => demande.statut === 'Crémation effectuée') && (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Crémations effectuées</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 10 }}>
                        {demandes
                          .filter(demande => demande.statut === 'Crémation effectuée')
                          .map(demande => renderDemandeCard(demande))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )} */}
              </Box>
            )
          )}

          <Fab
            color="primary"
            aria-label="add"
            onClick={handleOpenModal} // Assurez-vous que cette fonction ouvre le modal pour "Nouvelle demande"
            sx={{
              position: 'fixed',
              bottom: 80,
              right: 30,
              backgroundColor: "#1976D2"
            }}
          >
            <AddIcon />
          </Fab>

        </>
      )}

      {/* <AddDemandes demandeProp={viewDemande} open={openModal} isEditMode={false} userRole={userRole} onClose={handleCloseModal} userEmail={userEmail} clinique={clinique} /> */}

      <AddDemandes demandeProp={viewDemande} isEditMode={false} userRole={userRole} open={openModal} onClose={handleCloseModal} userEmail={userEmail} clinique={clinique} userID={userID} />
    </Container>
  );
};

export default Accueil;
