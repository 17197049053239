import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HistoryIcon from '@mui/icons-material/History';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import './App.css';
import LoginForm from './login';
import { db, auth, storage } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Users from './users';
import { AddDemandes, GetDemandes } from './demandes';
import { Check } from './check';
import { AlertProvider, useAlert } from './AlertContext';
import { Fab, CircularProgress, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Accueil from './accueil';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { doc, getDoc } from 'firebase/firestore';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from './img/logo1.png';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import EuroIcon from '@mui/icons-material/Euro';
import CalculateIcon from '@mui/icons-material/Calculate';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PeopleIcon from '@mui/icons-material/People';
import { AddFournitures, GetFournitures } from './fournitures';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { GetCommandes } from './commander';
import InventoryIcon from '@mui/icons-material/Inventory';
import { GetInvoices } from './invoices';
import PhoneIcon from '@mui/icons-material/Phone';
import { setDoc, collection, getDocs, query, where, onSnapshot } from 'firebase/firestore';
import { GetUrnes, AddUrnes } from './urnes';
import HourglassBottom from '@mui/icons-material/HourglassBottom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Chiffres from './chiffres';
import Badge from '@mui/material/Badge';
import { getDownloadURL, ref } from 'firebase/storage';
import MessageAdmin from './messageAdmin';
import LanguageIcon from '@mui/icons-material/Language';

const drawerWidth = 250;

const navigationItems = [
  { label: "Accueil", path: "/", icon: <HomeIcon /> },
  { label: "Nouvelle demande", path: "/", icon: <AssignmentIcon />, action: 'openModal' },
  { label: "Demandes", path: "/getDemandes", icon: <HistoryIcon /> },
  { label: "Fournitures", path: "/getCommandes", icon: <ShoppingCartIcon /> },
  { label: "Urnes", path: "/getUrnes", icon: <HourglassBottom /> },
  { label: "Factures", path: "/getInvoices", icon: <EuroIcon /> },
  { label: "Ramassage", path: "/check", icon: <QrCodeScannerIcon />, userRole: "admin" },
  { label: "Utilisateurs", path: "/users", icon: <PersonIcon />, userRole: "admin" },
  { label: "Messages", path: "/messages", icon: <NotificationsIcon />, userRole: "admin" },
  { label: "Gérer les fournitures", path: "/getFournitures", icon: <InventoryIcon />, userRole: "admin" },
  { label: "Chiffres", path: "/chiffres", icon: <CalculateIcon />, userRole: "admin" },
];
// function MainComponent() {
//   const { showAlert } = useAlert();

//   const handleClick = () => {
//     showAlert('Ceci est un message d\'alerte', 'success');
//   };

//   return (
//     <div>
//       <button onClick={handleClick}>Afficher l'alerte</button>
//     </div>
//   );
// }

const version = process.env.REACT_APP_VERSION;

// const useUsers = () => {
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const userCollectionRef = collection(db, "veto", user.uid);
//         const querySnapshot = await getDocs(userCollectionRef);
//         console.log(querySnapshot);
//         const usersData = querySnapshot.docs.map(doc => ({
//           email: doc.data().email,
//           clinique: doc.data().clinique
//         }));
//         setUsers(usersData);
//       } catch (error) {
//         console.error("Error fetching users:", error);
//       }
//     };

//     fetchUsers();
//   }, []);

//   return users;
// };

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [userClinique, setUserClinique] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userID, setUserID] = useState('');
  const [userRole, setUserRole] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(isMobile);
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState(0);
  const getDemandesRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  // const users = useUsers();
  // const { alert, closeAlert } = useAlert();
  const [isFullScreenIOS, setIsFullScreenIOS] = useState(false);
  const [hasRecentCommandes, setHasRecentCommandes] = useState(false);
  const [conventionUrl, setConventionUrl] = useState('');

  const open = Boolean(anchorEl);
  useEffect(() => {
  }, [userClinique]);
  useEffect(() => {
    // console.log(users);
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setIsLoggedIn(true);
        setLoading(false);
        user.getIdTokenResult().then(async token => {
          setUserToken(token.token);
          setUserRole(token.claims.admin ? 'admin' : 'veto');
          const userRef = collection(db, "veto");
          const userQuery = query(userRef, where("UID", "==", user.uid));
          const querySnapshot = await getDocs(userQuery);
          const docSnap = querySnapshot.docs;
          if (docSnap) {
            const usersData = [];
            docSnap.forEach(doc => {
              usersData.push({ id: doc.id, ...doc.data() });
            });
            setUsers(usersData);
            const storedClinique = localStorage.getItem('clinique');
            if (!storedClinique) {
              setUserClinique(usersData[0]?.clinique);
              setUserEmail(usersData[0]?.email);
              setUserID(usersData[0]?.id);
              localStorage.setItem('clinique', usersData[0]?.clinique);
            } else {
              const userWithStoredClinique = usersData.find(user => user.clinique === storedClinique);
              if (userWithStoredClinique) {
                setUserClinique(userWithStoredClinique.clinique);
                setUserEmail(userWithStoredClinique.email);
                setUserID(userWithStoredClinique.id);
              } else {
                setUserClinique(usersData[0]?.clinique);
                setUserEmail(usersData[0]?.email);
                setUserID(usersData[0]?.id);
                localStorage.setItem('clinique', usersData[0]?.clinique);
              }
            }
          } else {
            console.log("No such user in Firestore!");
          }
        });
      } else {
        setIsLoggedIn(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userClinique && userID) {
      const logUserAction = async () => {
        // console.log("user", user);
        const logRef = doc(collection(db, "logs"));
        await setDoc(logRef, {
          userId: userID,
          email: userEmail,
          clinique: userClinique,
          timestamp: new Date(),
          action: "login",
          version: version,
          browserInfo: {
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            language: navigator.language
          },
          windowInfo: {
            width: window.innerWidth,
            height: window.innerHeight
          }
        });
      };
  
      logUserAction();
    }
  }, [userClinique, userID, userEmail]);

  useEffect(() => {
    function detectFullScreenIOS() {
      const isInStandaloneMode = window.navigator.standalone;
      setIsFullScreenIOS(isInStandaloneMode);
    }

    detectFullScreenIOS();
    window.addEventListener('resize', detectFullScreenIOS);

    return () => {
      window.removeEventListener('resize', detectFullScreenIOS);
    };
  }, []);

  useEffect(() => {
    const checkRecentCommandes = () => {
      const commandesCollectionRef = collection(db, "commandes");
      const unsubscribe = onSnapshot(commandesCollectionRef, (snapshot) => {
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
        
        const hasRecent = snapshot.docs.some(doc => {
          const data = doc.data();
          return new Date(data.created) > twoDaysAgo;
        });
        
        setHasRecentCommandes(hasRecent);
      });

      return unsubscribe;
    };

    const unsubscribe = checkRecentCommandes();
    return () => unsubscribe && unsubscribe();
  }, []);

  useEffect(() => {
    const getConventionUrl = async () => {
      try {
        const fileRef = ref(storage, 'docs/demande de convention papier.docx');
        const url = await getDownloadURL(fileRef);
        setConventionUrl(url);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'URL du document:", error);
      }
    };
    getConventionUrl();
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.signOut();
    setIsLoggedIn(false);
    handleClose();
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleOpenModal = (event) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleUpdateDemandes = async () => {
    if (getDemandesRef.current) {
      await getDemandesRef.current.fetchDemandes();
    }
  };

  const handleChangeProfile = (user) => {
    setUserClinique(user.clinique);
    setUserEmail(user.email);
    setUserID(user.id);
    localStorage.setItem('clinique', user.clinique);
    handleClose();
  };

  // const AlertSnackbar = () => {
  //   const { alert, closeAlert } = useAlert();

  //   return (
  //     <Snackbar
  //       open={alert.open}
  //       autoHideDuration={6000}
  //       onClose={closeAlert}
  //     >
  //       <Alert onClose={closeAlert} severity={alert.severity} sx={{ width: '100%' }}>
  //         {alert.message}
  //       </Alert>
  //     </Snackbar>
  //   );
  // };

  if (!isLoggedIn && !loading) {
    return <LoginForm />;
  }
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress size={80} />
      </div>
    );
  }

  return (
    <AlertProvider>
      <Router>
        <div style={{ display: 'flex', height: '100vh' }}>
          <CssBaseline />

          {/* {!isMobile && ( */}
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#EB984E" }}>
            <Toolbar>
              <img src={logo} alt="My Image" height={"60px"} />
              {!isMobile && (
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
                  Crématorium Animalier de Picardie
                </Typography>
              )}
              {/* <div style={{ marginLeft: 'auto' }}>
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </div> */}
              <Typography variant="body1" sx={{ marginRight: 2, display: isMobile ? "none" : null, border: "1px solid white", padding: 1 }}>
                <PhoneIcon sx={{ marginRight: 1, verticalAlign: "middle" }} /> Urgence vétérinaire : 06 83 77 89 27
              </Typography>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                onClick={handleMenu}
                sx={{ ml: 'auto', mr: 2 }}
              >
                <AccountCircleIcon />
              </IconButton>
              <Typography variant="body1" sx={{ marginRight: 2, display: isMobile ? "none" : null }}>
                {userClinique}
              </Typography>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
                sx={{
                  mt: '8px', // Marge au-dessus pour le décaler sous l'AppBar
                  ml: 'auto', // Alignement à droite
                  mr: '8px', // Marge à droite pour l'alignement
                }}
              >
                {users.length > 1 &&
                  users
                    .sort((a, b) => (a.clinique === userClinique ? -1 : b.clinique === userClinique ? 1 : 0))
                    .map((user, index) => (
                      <MenuItem 
                        key={index} 
                        onClick={() => handleChangeProfile(user)}
                        style={{ backgroundColor: user.clinique === userClinique ? '#f0f0f0' : 'inherit' }}
                      >
                        {user.clinique !== userClinique && (
                          <>Basculer vers  <ArrowForwardIcon style={{ marginRight: '8px' }} /></>
                        )}
                        <AccountCircleIcon style={{ marginRight: '8px' }} /> 
                        {user.clinique}
                        {user.clinique === userClinique && (
                          <CheckIcon style={{ color: 'green', marginLeft: '8px' }} />
                        )}
                      </MenuItem>
                    ))}
                <MenuItem>
                  <Button
                    onClick={handleLogout}
                    startIcon={<ExitToAppIcon />}
                    style={{ color: 'red', fontWeight: 600 }}
                  >
                    Se déconnecter
                  </Button>
                </MenuItem>
                <MenuItem style={{ fontSize: '0.7em', color: 'gray' }}> {/* Style pour la version */}
                  Développé par Matthieu SIMAR ({version})
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>

          <Box sx={{ position: 'absolute', top: '60px', left: 0, right: 0, height: isMobile ? '130px' : '230px', overflow: 'hidden', zIndex: -1, borderBottomLeftRadius: '15px', borderBottomRightRadius: '8px' }}>
            <img src="/bandeau.png" alt="Bandeau" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center 38%' }} />
          </Box>
          {/* )} */}
          {!isMobile && (
            <Drawer
              variant="permanent"
              sx={{
                width: !mobileMenuOpen ? drawerWidth : "50px",
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: !mobileMenuOpen ? drawerWidth : "50px", boxSizing: 'border-box', top: '294px' }
              }}
            >
              <List>
                {navigationItems.map(item => {
                  if (item.userRole === "admin" && userRole !== 'admin') {
                    return null;
                  }
                  return (
                    <ListItem key={item.label} disablePadding>
                      <ListItemButton
                        component={item.action === 'openModal' ? 'button' : Link}
                        to={item.path}
                        onClick={item.action === 'openModal' ? handleOpenModal : null}
                      >
                        <ListItemIcon>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText 
                          primary={
                            !mobileMenuOpen ? (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {item.label}
                                {item.label === "Fournitures" && hasRecentCommandes && (
                                  <div style={{ 
                                    width: '10px', 
                                    height: '10px', 
                                    backgroundColor: 'red', 
                                    borderRadius: '50%', 
                                    marginLeft: '8px' 
                                  }} />
                                )}
                              </div>
                            ) : null
                          } 
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 'auto', justifyContent: 'space-between' }}>
                <div style={{ flexGrow: 0 }}>
                  <Box sx={{ 
                    padding: 2, 
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    backgroundColor: '#f5f5f5'
                  }}>
                    <Button
                      variant="text"
                      startIcon={<DescriptionIcon />}
                      component="a"
                      href={conventionUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ 
                        textTransform: 'none',
                        justifyContent: 'flex-start',
                        color: '#1976D2',
                        '&:hover': {
                          backgroundColor: 'rgba(25, 118, 210, 0.04)'
                        }
                      }}
                    >
                      Convention papier
                    </Button>
                  </Box>
                </div>
                <div style={{ paddingBottom: 20, paddingRight: 0.2 }}>
                  <img src="/crema.jpeg" alt="Crématorium" style={{ width: '100%' }} />
                </div>
                <div style={{ paddingBottom: 300, paddingTop: 10, paddingRight: 0.2, backgroundColor: "#f1c40f", border: "1px solid #f6b93b", flexShrink: 0 }}>
                  <Typography variant="body1" sx={{ color: "white", marginLeft: 2, fontWeight: 500 }}>
                    {"380 rue du Séhu, ZA la Hayette"}<br />
                    {"80290 Poix-de-Picardie"}<br />
                    {"Tél : 03 22 69 51 16"}<br />
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <LanguageIcon sx={{ mr: 1, fontSize: 20 }} />
                      <a 
                        href="https://www.crematoriumanimalierdepicardie.fr" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ 
                          color: 'white', 
                          textDecoration: 'none',
                          fontWeight: 500
                        }}
                      >
                        Notre site web
                      </a>
                    </Box>
                  </Typography>
                </div>
              </div>
            </Drawer>
          )}
          {isMobile && (
            <div style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              overflowX: 'scroll',
              overflowY: 'hidden',
              whiteSpace: 'nowrap',
              display: 'flex',
              width: 'auto',
              height: isFullScreenIOS ? 80 : 70,
              zIndex: 10,
              backgroundColor: '#f5f5f5',
              paddingBottom: isFullScreenIOS ? 'env(safe-area-inset-bottom)' : 0
            }}>
              <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
                style={{
                  display: 'flex',
                  width: 'max-content',
                  paddingBottom: 0,
                  backgroundColor: 'inherit',
                  boxShadow: '0px -2px 4px rgba(0,0,0,0.1)'
                }}
              >
                {navigationItems.map(item => {
                  if (item.userRole === "admin" && userRole !== 'admin' || item.label == "Nouvelle demande") {
                    return null;
                  }
                  return (
                    <BottomNavigationAction
                      key={item.label}
                      label={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {item.label}
                          {item.label === "Fournitures" && hasRecentCommandes && (
                            <div style={{ 
                              width: '8px', 
                              height: '8px', 
                              backgroundColor: 'red', 
                              borderRadius: '50%', 
                              marginLeft: '4px',
                              marginTop: '-8px'
                            }} />
                          )}
                        </div>
                      }
                      icon={item.icon}
                      component={Link}
                      to={item.path}
                      onClick={item.action === 'openModal' ? handleOpenModal : null}
                    />
                  );
                })}
              </BottomNavigation>
            </div>
          )}

          <main style={{ flexGrow: 1, padding: '24px', marginTop: isMobile ? '160px' : '300px', marginBottom: isMobile ? '156px' : '0' }}>
            <Routes>
              <Route path="/" element={<Accueil userRole={userRole} userEmail={userEmail} clinique={userClinique} userID={userID} />} />
              {userRole === "admin" && <Route path="/users" element={<Users />} />}
              <Route path="/" element={<AddDemandes isEditMode={true} userRole={userRole} onUpdateDemandes={handleUpdateDemandes} isNewDemande={true} userID={userID} userEmail={userEmail} clinique={userClinique}/>} />
              <Route path="/getDemandes" element={<GetDemandes userRole={userRole} clinique={userClinique} userID={userID} />} />
              {userRole === "admin" && <Route path="/check" element={<Check userEmail={userEmail} />} />}
              {userRole === "admin" && <Route path="/getFournitures" element={<GetFournitures userRole={userRole} />} />}
              <Route path="/getCommandes" element={<GetCommandes userRole={userRole} clinique={userClinique} userID={userID} />} />
              <Route path="/getInvoices" element={<GetInvoices userRole={userRole} clinique={userClinique} userID={userID} />} />
              <Route path="/getUrnes" element={<GetUrnes userRole={userRole} />} />
              {userRole === "admin" && <Route path="/chiffres" element={<Chiffres />} />}
              <Route path="/messages" element={<MessageAdmin userRole={userRole} />} />
            </Routes>
          </main>
          <AddDemandes isEditMode={true} userRole={userRole} open={modalOpen} onClose={handleCloseModal} userEmail={userEmail} clinique={userClinique} userID={userID} />
        </div>
      </Router>
    </AlertProvider>
  );
}

export default App;
