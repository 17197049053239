import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from './firebase';
import { 
  Container, 
  Grid, 
  Card, 
  CardContent, 
  Typography, 
  CircularProgress, 
  Box, 
  useMediaQuery, 
  useTheme,
  Divider,
  Paper 
} from '@mui/material';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  BarElement, 
  CategoryScale, 
  LinearScale, 
  Tooltip, 
  Legend, 
  ArcElement, 
  Title
} from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ArcElement, Title);

const Chiffres = () => {
  const [demandes, setDemandes] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const q = query(collection(db, 'demandes'), where("deleted", "!=", true));
    const unsubscribe = onSnapshot(q, snapshot => {
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDemandes(data);
      
      // Pour le débogage - vérification des valeurs des champs
      console.log("Types de valeurs pour les champs urne et pierreEternelle:");
      const sample = data.slice(0, 5); // Examiner les 5 premières demandes
      sample.forEach((d, i) => {
        console.log(`Demande ${i}:`, {
          urne: d.urne,
          typeUrne: typeof d.urne,
          pierreEternelle: d.pierreEternelle,
          typePierreEternelle: typeof d.pierreEternelle
        });
      });
      
      // Compteur pour le débogage
      const pierresCount = data.filter(d => d.pierreEternelle === true).length;
      const pierresCountAlt = data.filter(d => d.pierreEternelle).length;
      const pierresCountFunc = data.filter(d => isTrue(d.pierreEternelle)).length;
      
      console.log("Statistiques des pierres éternelles:");
      console.log("- Comparaison stricte (===true):", pierresCount);
      console.log("- Conversion booléenne (if d.pierreEternelle):", pierresCountAlt);
      console.log("- Fonction isTrue():", pierresCountFunc);
      
      setLoading(false);
    }, error => {
      console.error("Erreur lors de la récupération des données:", error);
      setLoading(false);
    });
    
    return () => unsubscribe();
  }, []);

  // Statistiques globales
  const totalDemandes = demandes.length;
  const demandesTerminees = demandes.filter(d => d.statut === 'Crémation effectuée' || d.statut === 'Urne restituée').length;
  const demandesEnAttente = demandes.filter(d => d.statut !== 'Crémation effectuée' && d.statut !== 'Urne restituée').length;
  
  // Répartition par type de crémation
  const typesCremation = demandes.reduce((acc, demande) => {
    if (!demande.cremationType) return acc;
    
    const type = demande.cremationType;
    if (!acc[type]) acc[type] = 0;
    acc[type]++;
    return acc;
  }, {});

  // Fonction utilitaire pour vérifier si une valeur est vrai de façon cohérente
  const isTrue = (value) => {
    return value === true || value === 'true' || value === 1 || value === '1';
  };

  // Répartition des demandes avec urnes et pierres éternelles
  const demandesAvecUrne = demandes.filter(d => isTrue(d.urne)).length;
  const demandesAvecPierre = demandes.filter(d => isTrue(d.pierreEternelle)).length;
  const demandesAvecUrneEtPierre = demandes.filter(d => isTrue(d.urne) && isTrue(d.pierreEternelle)).length;
  const demandesSansOption = totalDemandes - (demandesAvecUrne + demandesAvecPierre - demandesAvecUrneEtPierre);

  // Grouper par mois le nombre de crémations
  const monthlyData = {};
  demandes.forEach(d => {
    if (d.cremaDate) {
      const date = new Date(d.cremaDate);
      const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      
      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = { 
          total: 0,
          types: {}
        };
      }
      
      monthlyData[monthKey].total += 1;
      
      if (d.cremationType) {
        if (!monthlyData[monthKey].types[d.cremationType]) {
          monthlyData[monthKey].types[d.cremationType] = 0;
        }
        monthlyData[monthKey].types[d.cremationType] += 1;
      }
    }
  });

  // Fonction pour convertir une chaîne de mois en objet Date pour le tri
  const parseMonthYear = (monthYearStr) => {
    const [month, year] = monthYearStr.split(' ');
    const monthNames = [
      'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
      'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
    ];
    const monthIndex = monthNames.indexOf(month.toLowerCase());
    return new Date(parseInt(year), monthIndex);
  };

  // Extraire les labels et datasets pour l'histogramme
  const sortedMonths = Object.keys(monthlyData)
    .sort((a, b) => parseMonthYear(a) - parseMonthYear(b))
    .slice(-12); // Afficher seulement les 12 derniers mois

  // Les types de crémation à afficher
  const cremationTypes = [
    'Crémation collective',
    'Crémation individuelle accompagnée',
    'Crémation individuelle non accompagnée'
  ];

  // Couleurs pour les graphiques
  const colors = ['#F1C40F', '#1b5e20', '#7B241C', '#3498DB', '#8E44AD'];

  // Préparer les datasets pour le graphique empilé par mois
  const datasets = cremationTypes.map((type, index) => ({
    label: type,
    data: sortedMonths.map(month => {
      return monthlyData[month].types[type] || 0;
    }),
    backgroundColor: colors[index % colors.length]
  }));

  // Configuration du graphique en barres
  const barChartData = {
    labels: sortedMonths,
    datasets
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: isMobile ? 'bottom' : 'top',
        labels: {
          boxWidth: isMobile ? 10 : 20,
          font: {
            size: isMobile ? 10 : 12
          }
        }
      },
      title: {
        display: true,
        text: 'Crémations par mois',
        font: {
          size: isMobile ? 14 : 18
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          maxRotation: isMobile ? 90 : 0,
          minRotation: isMobile ? 45 : 0,
          font: {
            size: isMobile ? 8 : 12
          }
        }
      },
      y: {
        stacked: true,
        ticks: {
          font: {
            size: isMobile ? 10 : 12
          }
        }
      }
    }
  };

  // Données pour le graphique en anneau des types de crémation
  const doughnutData = {
    labels: Object.keys(typesCremation),
    datasets: [
      {
        data: Object.values(typesCremation),
        backgroundColor: colors,
        borderWidth: 1
      }
    ]
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '65%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: isMobile ? 8 : 20,
          padding: isMobile ? 5 : 10,
          font: {
            size: isMobile ? 9 : 12
          }
        }
      },
      title: {
        display: true,
        text: 'Répartition des types de crémation',
        font: {
          size: isMobile ? 14 : 18
        }
      }
    }
  };

  // Données pour le graphique en anneau des options supplémentaires
  const optionsData = {
    labels: ['Demandes avec urne', 'Demandes avec pierre éternelle', 'Demandes sans options'],
    datasets: [
      {
        data: [demandesAvecUrne, demandesAvecPierre, demandesSansOption],
        backgroundColor: ['#2E86C1', '#8E44AD', '#16A085'],
        borderWidth: 1
      }
    ]
  };

  const optionsChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '65%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: isMobile ? 8 : 20,
          padding: isMobile ? 5 : 10,
          font: {
            size: isMobile ? 9 : 12
          }
        }
      },
      title: {
        display: true,
        text: 'Répartition des options',
        font: {
          size: isMobile ? 14 : 18
        }
      }
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Statistiques
      </Typography>

      {/* Cartes des statistiques globales */}
      <Typography variant="h5" sx={{ mb: 2 }}>Chiffres clés</Typography>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Total demandes</Typography>
              <Typography variant="h3" color="primary">{totalDemandes}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Crémations terminées</Typography>
              <Typography variant="h3" color="success.main">{demandesTerminees}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Demandes en attente</Typography>
              <Typography variant="h3" color="warning.main">{demandesEnAttente}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Avec pierre éternelle</Typography>
              <Typography variant="h3" color="secondary.main">{demandesAvecPierre}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Graphiques */}
      <Typography variant="h5" sx={{ mb: 2 }}>Analyses détaillées</Typography>
      <Grid container spacing={3}>
        {/* Graphique des crémations par mois */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: isMobile ? 1 : 2, borderRadius: 2 }}>
            <Box sx={{ height: isMobile ? 350 : 400, width: '100%', overflowX: isMobile ? 'auto' : 'hidden' }}>
              <Box sx={{ minWidth: isMobile ? 400 : '100%', height: '100%' }}>
                <Bar data={barChartData} options={barChartOptions} />
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Graphiques en anneau */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: isMobile ? 1 : 2, borderRadius: 2 }}>
            <Box sx={{ height: isMobile ? 280 : 300 }}>
              <Doughnut data={doughnutData} options={doughnutOptions} />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: isMobile ? 1 : 2, borderRadius: 2 }}>
            <Box sx={{ height: isMobile ? 280 : 300 }}>
              <Doughnut data={optionsData} options={optionsChartOptions} />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Tableau détaillé par mois */}
      <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Détail mensuel</Typography>
      <Paper elevation={3} sx={{ p: 2, borderRadius: 2, overflowX: 'auto' }}>
        <Box>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#f5f5f5' }}>
                <th style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Mois</th>
                <th style={{ padding: '10px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>Total</th>
                {cremationTypes.map((type, index) => (
                  <th key={index} style={{ padding: '10px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>
                    {isMobile ? type.split(' ')[1] : type}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedMonths.map((month, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{month}</td>
                  <td style={{ padding: '10px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>{monthlyData[month].total}</td>
                  {cremationTypes.map((type, idx) => (
                    <td key={idx} style={{ padding: '10px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>
                      {monthlyData[month].types[type] || 0}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Paper>
    </Container>
  );
};

export default Chiffres;